.footerContainer {
    display: flex;
    flex-direction: column;
    background-color: #8977C9;
    width: 100%;
    pointer-events: none;
}
  
.textArea {
    margin-bottom: -60px;
    max-width: 50%;
}

.textArea p {
    color: #FDCCE8;
    font-size: 24px;
    margin-left: 30px;
}

.footerImage {
    width: 100%;
    align-self: flex-end;
}

@media (max-width: 600px) {
    .textArea {
        margin-bottom: 0px;
        max-width: 90%;
    }

    .textArea p {
        font-size: 16px;
        margin-left: 15px;
    }
}
  