@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;500&family=PT+Sans&display=swap');

::selection {
    background: #d4faff; /* WebKit/Blink Browsers */
  }

h1 {
    font-family: 'Kanit', sans-serif;
    font-size: 70px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #FC9BD3;
    margin-top: 0px;
    margin-bottom: 5px;
    text-transform: uppercase;
}

h2 {
    font-family: 'Kanit', sans-serif;
    font-size: 50px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #FC9BD3;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 30px;
    text-transform: uppercase;
}

h3 {
    font-family: 'PT Sans';
    font-size: 26px;
    font-weight: 300;
    color: #FDCCE8;
    margin-top: 0px;
    margin-left: 30px;
}

p, ul {
    font-family: 'PT Sans';
    font-size: 16px;
    color: #332B48;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 1.4;
}

@media (max-width: 600px) {
    h1 {
        font-size: 36px; /* Smaller size for mobile */
        margin-bottom: 3px; /* Adjusted spacing for mobile */
        margin-left: 15px;
    }

    h2 {
        font-size: 30px; /* Smaller size for mobile */
        margin-bottom: 5px; /* Adjusted spacing for mobile */
        margin-left: 15px; /* Adjusted spacing for mobile */
    }

    h3 {
        font-size: 20px; /* Smaller size for mobile */
        margin-left: 15px;
    }

    p, ul {
        font-size: 14px; /* Slightly smaller size for mobile */
    }
}