.project-card-container {
    position: relative;
    background-color: #FDCCE8;
    width: 100%;
    height: 550px;
    display: flex;
    flex-direction: column;
    border: 5px solid #fc9bd3; /* Default border color */
    transition: border-color 0.3s;
}

.project-card-container:hover {
    border: 5px solid #D4FAFF; /* New border color on hover */
}

.read-more {
    position: absolute;
    bottom: 10px;
    right: 15px;
    color: #332b48;
    cursor: pointer;
    text-decoration: underline;
}

.read-more:hover {
    color: #D4FAFF; /* Change color on hover */
    /* Add any other hover styles here */
}

.arrow-icon {
    fill: #332b48; /* Initial arrow color */
}

.project-card-container:hover .arrow-icon {
    fill: #D4FAFF; /* Arrow color on card hover */
}

.overlay-text {
    font-size: 12px;
}

.icon {
    width: 24px;
    height: 24px;
}

.project-link {
    width: 550px;
}

.title-text {
    font-size: 30px;
}


/* Styles for screens less than 600px wide */
@media (max-width: 600px) {
    .overlay-text {
        font-size: 8px;
    }
    .project-link {
        width: 90%;
        justify-content: center;
    }
    .icon {
        width: 20px;
        height: 20px;
    }
    .title-text {
        font-size: 20px;
    }
}
