.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background-color: transparent; /* Initially transparent */
    z-index: 1000;
    transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0; /* Initially fully transparent */

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.fixed-header.not-scrolled {
    visibility: hidden; /* Keep the header hidden when not scrolled */
}

.fixed-header.scrolled {
    background-color: #332B48;
    opacity: 1;
    visibility: visible;
}

.text {
    font-size: 30px;
    margin-bottom: 0;
    color: #DABAFF;
    transition: color 0.3s;
}

.text:hover {
    color: #D4FAFF; /* Color on hover */
}

.icon-nav {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 600px) {
    .text {
      font-size: 20px; /* Smaller text size for mobile */
    }
  }

  @media (max-width: 500px) {
    .text {
      font-size: 11px; /* Smaller text size for mobile */
    }
  }