.bannericon {
    fill: currentColor;
    color: #DABAFF;
    transition: color 0.3s;
    width: 70px;
    height: 70px;
    position: relative;
    -webkit-user-drag: none;
    user-select: none;
  }
  
.bannericon:hover {
    color: #D4FAFF;
}

.videoicon {
    fill: #332B48;
}

@media (max-width: 600px) {
    .bannericon {
        width: 30px;
        height: 30px;
    }
}